@import url('https://fonts.googleapis.com/css?family=Encode+Sans:400,700&display=swap');

body {
  background-color: #2E2E3A;
}

.appWrapper {
  position: relative;
  overflow-x: hidden;
  width: 100vw;
}

.link {
  color: #ffffff;
  text-decoration: none
}

body, span, h1, h2, h3, h4, h5, h6, input, textarea {
  font-family: 'Encode Sans', sans-serif;
  letter-spacing: 1.25Px;
  line-height: 2rem;
  margin: 0;
  padding: 0;
  outline: none
}

h1 {
  font-size: 1.4rem;
  font-weight: 700
}

h2 {
  font-size: 1.1rem;
}

h3 {
  font-size: 1rem;
  font-weight: 400
}

h4 {
  font-size: .8rem;
  font-weight: 400
}

@media only screen and (min-width: 360px) {
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1rem;
  }

  h4 {
    font-size: .9rem
  }
}

@media only screen and (min-width: 540px) {
  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h4 {
    font-size: 1rem
  }
}

@media only screen and (min-width: 860px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.1rem
  }
}

::selection,
::-moz-selection {
  background: #2E2E3A44;
}

.button {
  display: inline-block;
  text-align: center;
  padding: 1em 0;
  color: white;
  border: none;
  border-radius: 5px;
}

.appBackground {
  background-image: url(./Assets/diagonal-noise.png);
  margin-top: 10vh;
  min-height: 90vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f2f3
}

/* Header */

.headerBackground {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100vw;
  height: 10vh;
  display: flex;
  color: #fff;
  background-color: #2E2E3A;
  box-shadow: 0 4px 8px #EAEAEA44
}

.headerContain {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleContain {
  display: flex;
  flex-direction: row;
}

.logoContain {
  height: 6vh;
  padding-right: 1em
}

.profile {
  height: 6vh
}

.menuContain {
  height: 4.5vh;
  width: 4.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh;
  border-radius: 100px;
}

.menuContain:hover,
.menuContain:focus {
  background-color: #212223
}

.menu {
  height: 4vh;
}

.profile {
  border-radius: 3vh;
  border: 1px solid #f1f2f3
}

.titleTextContain {
  height: 6vh;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: space-around;
}

.titleTextContain :first-child {
  font-weight: 700
}

.titleTextContain :first-child:hover {
  color: #51C5F8;
}

.tabContain {
  display: flex
}

.tabItem, .emailTab {
  font-weight: 400;
  padding: .5em 1em;
  box-shadow: none;
}

.tabItem {
  color: #ffffff;
}

.activeTabItem {
  color: #51C5F8;
}

.tabItem:hover,
.tabItem:focus {
  color: #51C5F8;
}

.emailTab {
  color: #ffffff88;
}

.emailTab:hover,
.emailTab:focus{
  color: #F34213
}

/* Drawer Contain */

.backdrop {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  z-index: 7;
  background-color: #00000033;
}

.drawerContain {
  width: 60vw;
  height: 100vh;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: white;
  box-shadow: -3px 0 10px #00000099;
}

.menuListMobile {
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  animation-delay: .6s;
}

.mobileTab {
  color: #000000;
  width: 55vw;
  text-align: right;
  padding: 2vh 5vw 2vh 0;
  text-decoration: none
}

.mobileTab:hover,
.mobileTab:focus {
  color: #ffffff;
  background-color: #51C5F8
}

.crossContain {
  height: 10vh;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.exitMenuIcon {
  height: 4vh;
  margin-right: 5vw
}

/* Splash Contain */

.homeBackground {
  min-height: 90vh;
}

.splashBackground {
  z-index: 1;
  height: 90vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 60vh 1fr;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.splashCatchContain {
  grid-row: 2;
  z-index: 4;
  padding-left: 5vw;
  width: 90vw;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: splashOpacity 2s cubic-bezier(.21,.07,.3,.97)
}

@keyframes splashOpacity {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1
  }
}

.splashCatchContain h1 {
  font-size: 2em;
}

.splashCatchContain h4 {
  padding-top: 2em;
}

.contactLinkContain {
  z-index: 9;
  grid-row: 3;
  justify-self: flex-start;
  align-self: center;
  padding-left: 5vw;
  animation: splashOpacity 3s cubic-bezier(.21,.07,.3,.97)
}

.contactLink {
  font-weight: 700;
  color: #51C5F8;
  padding: .3em 0;
  border-bottom: 3px solid #F34213;
}

.splashAssetBackground {
  z-index: 2;
  position: absolute;
  display: flex;
  height: 90vh;
  width: 100vw;
}

.splashAssetContain {
  align-self: flex-end;
  display: flex;
  height: 50vh;
  animation: splashOpacity 4s cubic-bezier(.21,.07,.3,.97)
}


.me, .heroIcon {
  width: 100vw;
  align-self: flex-end;
}

.heroIcon {
  z-index: 3;
  position: absolute;
  filter: drop-shadow(0px 3px 1px rgb(0 0 0 / 0.25));
  animation: iconHover 1s cubic-bezier(.21,.07,.3,.97) infinite;
  animation-direction: alternate-reverse;
}

@keyframes iconHover {
  0% {
      transform: translateY(3px);
  }
  100% {
      transform: translateY(0)
  }
}

.contactLink:hover,
.contactLink:focus {
  color: #F34213;
  border-bottom: 6px solid #F34213;
  -webkit-transition: border .3s cubic-bezier(.21,.07,.3,.97), color .3s cubic-bezier(.21,.07,.3,.97); /* For Safari 3.1 to 6.0 */
  transition: border .3s cubic-bezier(.21,.07,.3,.97), color .3s cubic-bezier(.21,.07,.3,.97);
}

.inputs {
  width: 30vw;
  max-width: 300px;
  position: absolute;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.LP {
  animation: fadeLP 3.6s cubic-bezier(.21,.07,.3,.97) infinite
}

.HP {
  animation: fadeHP 3.6s cubic-bezier(.21,.07,.3,.97) infinite
}

.LK {
  animation: fadeLK 3.6s cubic-bezier(.21,.07,.3,.97) infinite
}

.HK {
  animation: fadeHK 3.6s cubic-bezier(.21,.07,.3,.97) infinite
}

@keyframes fadeLP {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0
    }
    75% {
        opacity: 0
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeHP {
    0% {
        opacity: 0
    }
    25% {
        opacity: 1
    }
    50% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}

@keyframes fadeLK {
    0% {
        opacity: 0
    }
    25% {
        opacity: 0
    }
    50% {
        opacity: 1
    }
    75% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
  }

@keyframes fadeHK {
    0% {
        opacity: 0
    }
    50% {
        opacity: 0
    }
    75% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@media only screen and (min-width: 767px) {
  .splashBackground {
    height: 50vh;
    max-width: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 35vh 1fr;
  }

  .splashCatchContain {
    align-self: flex-start;
    grid-column: 1;
    grid-row: 2;
    animation: splashOpacity 7s cubic-bezier(.21,.07,.3,.97)
  }

  .contactLinkContain {
    align-self: flex-end;
    grid-column: 1;
    grid-row: 2;
    animation: splashOpacity 8s cubic-bezier(.21,.07,.3,.97)
  }

  .catchTextContain {
    justify-content: flex-start;
  }

  .buttonContact {
    max-width: 160px
  }

  .splashAssetBackground {
    z-index: 2;
    position: absolute;
    display: flex;
    height: 50vh;
  }
  
  .splashAssetContain {
    align-self: flex-end;
    display: flex;
    height: 50vh;
    animation: handGesture 5s cubic-bezier(.21,.07,.3,.97);
  }
  
  @keyframes handGesture {
    0% {
        transform: translateY(-20vh) scale3d(3,3,3);
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        transform: translateY(-20vh) scale3d(3,3,3);
    }
    100% {
        transform: translateY(0) scale3d(1,1,1)
    }
  }
}

@media screen and (min-width: 1024px) and (orientation: landscape){
  .splashBackground {
    height: 90vh;
  }

  .splashContain {
    width: 75vw;
    display: grid;
    grid-template-columns: repeat(2, 35vw);
    grid-column-gap: 5vw;
    grid-template-rows: 1fr
  }

  .splashAssetBackground {
    height: 90vh;
  }

  @keyframes handGesture {
    0% {
        transform: translateY(50vh) scale3d(3,3,3);
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        transform: translateY(50vh) scale3d(3,3,3);
    }
    100% {
        transform: translateY(0) scale3d(1,1,1)
    }
  }
}

/* Slill List */

.skillListBackground {
  z-index: 7;
  position: relative;
  background-color: #2E2E3A;
  color: #ffffff;
  display: flex;
  padding: 2em 0;
  align-items: center;
  justify-content: center
}

.skillListContain {
  width: 90vw;
  display: flex;
  flex-direction: column;
}

.skillContain {
  width: 90vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  max-width: 800px;
  padding: 1em 0
}

.skillName {
  grid-column: 1;
  grid-row: 1;
  font-style: italic;
  text-align: center;
}

.skillIcon {
  width: 50vw;
  grid-column: 1;
  grid-row: 2;
  justify-self: center;
  padding: 1rem 0;
}

.skillDetails {
  grid-column: 1;
  grid-row: 3;
}

@media screen and (min-width: 767px) {
  .skillContain {
    padding: 3em 0;
  }
}

@media screen and (min-width: 1024px) and (orientation: landscape){

  .skillListContain {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .skillContain {
    width: 25vw;
    display: flex;
    flex-direction: column;
  }

  .skillName {
    padding: 0;
    justify-self: center;
    align-self: center;
  }

  .skillIcon {
    width: 20vw;
    align-self: center;
    padding: 3em 0;
  }

  .skillDetails {
    padding: 0;
  }
}

/* Contact Card */

.contactBackground {
  width: 90vw;
  padding: 2em 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactContain {
  width: auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background-color: #C9E0F5;
  padding: 5vw;
  border-radius: 20px;
  box-shadow: 1px 3px 3px 0px rgba(0,0,0,0.44);
}

.contactTitle, .contactDetails {
  padding-bottom: 1em;
}

.contactForm {
  position: relative;
  align-self: center;
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.inputContain {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputTitle {
  margin-bottom: .7em
}

.contactInput {
  width: auto;
  font-size: 1em;
  padding: .75em 2.25vw;
  margin-bottom: 1em;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border: none;
  border-bottom: 3px solid #d1d1d1;
  animation: borderBottomInactive .25s cubic-bezier(.21,.07,.3,.97)
}

@keyframes borderBottomInactive {
  0% {
    border-bottom: 3px solid #51C5F8;
    box-shadow: 0 0 7px #d1d1d1aa;
  }
  100% {
    border-bottom: 3px solid #d1d1d1;
    box-shadow: none;
  }
}

.active {
  border-bottom: 3px solid #51C5F8;
  box-shadow: 0 0 7px #d1d1d1aa;
  animation: borderBottomActive .25s cubic-bezier(.21,.07,.3,.97)
}

@keyframes borderBottomActive {
  0% {
    border-bottom: 3px solid #d1d1d1;
    box-shadow: none;
  }
  100% {
    border-bottom: 3px solid #51C5F8;
    box-shadow: 0 0 7px #d1d1d1aa;
  }
}

.messageInput {
  min-height: 20vh;
  margin-bottom: 2em;
}

.contactSubmit {
  width: 100%;
  background-color: #51C5F8;
  font-size: 1em;
  box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.22);
}

.contactSubmit:hover,
.contactSubmit:focus {
  color: #000000;
  background-color: #F34213;
}

.successMessageBackground {
  position: absolute;
  width: 80vw;
  max-width: 500px;
  height: 100%;
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeStart .75s cubic-bezier(.21,.07,.3,.97)
}

.successMessageContain {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 30px;
  margin: 10px;
  box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.22);
}

.bartAsset {
  width: 50vw;
  max-width: 320px;
}

.errorMessage {
  color: #FF0054;
  margin-top: 4px;
  text-align: left;
  animation: fadeStart 2s cubic-bezier(.21,.07,.3,.97);
}

@media screen and (min-width: 1024px) and (orientation: landscape){
  .contactBackground {
    width: 75vw;
    padding: 3em 12.5vw;
  }

  .contactContain {
    padding: 2.5vw;
  }

  .contactInput {
    padding: .75em 1.25vw;
  }

  .messageInput {
    min-height: 10vh;
  }
}

/* Projects Contain */

.projectBackground {
  width: 100vw;
  background-color: #f1f2f3;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectSelectBackground {
  display: flex;
  align-items: center;
  min-height: 90vh;
}

/* mobileView */

.projectInnerContain {
  width: 90vw;
  max-width: 500px;
  margin: 0 auto
}

.projectContainTitle {
  padding-bottom: 1em;
  align-self: center;
}

.projectCardListContain {
  display: flex;
  flex-direction: column;
  align-items: center
}

.projectCardListContain :last-child {
  margin: 0;
}

.projectCard {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 4px #2E2E3A;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.projectCardHeader {
  margin: 1em;
  display: flex
}

.cardIcon {
  height: 10vw;
  max-height: 50px;
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
  align-self: center
}

.projectCardIcon {
  display: flex;
  align-items: center;
  justify-content: center
}

.projectCardHeaderText {
  width: 100%;
  height: inherit;
  padding-left: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.projectCardImg {
  background-color: #2E2E3A;
  width: 90vw;
  max-width: 500px;
  height: 50.625vw;
  max-height: 281.25px;
}

.projectCardBody {
  margin: 1em;
}

.projectDetails {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 2;
  justify-self: center;
  align-self: center
}

.projectLink {
  background: none;
  border: none;
  padding: .75em;
  color: #51C5F8;
  font-weight: 700;
  align-self: flex-end;
  background-color: #00000000;
  border: 0;
}

@media screen and (min-width: 1024px) and (orientation: landscape){

  .projectBackground {
    min-height: 0
  }

  .projectInnerContain {
    max-width: none;
    padding: 0;
  }

  .projectContainTitle {
    margin: 0 7.5vw
  }

  .projectContain-Desktop {
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .animationCanvas {
    grid-column: 1;
    position: relative;
    width: 50vw;
    height: 33.3087vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .projectSelectContain {
    z-index: 4;
    width: inherit;
    height: inherit
  }

  .projectSelectCenterTitle {
    position: absolute;
    animation: fadeUpStart 1.2s cubic-bezier(.21,.07,.3,.97);
    justify-self: center;
    align-self: center
  }

  .SVG, .blankSVG {
    width: 30.6792%;
    max-width: 666.174px;
    position: absolute;
  }

  .SVG:hover,
  .SVG:focus {
    z-index: 6;
    width: 34%;
  }

  .SVG-1 {
    opacity: .5;
    animation: svg1 1.2s cubic-bezier(.21,.07,.3,.97);
  }

  .SVG-2 {
    margin-top: 26.6792%;
    animation: svg2 1.2s cubic-bezier(.21,.07,.3,.97);
  }

  .SVG-3 {
    opacity: .5;
    margin-left: 23.0769%;
    margin-top: 39.9705%;
    animation: svg3 1.2s cubic-bezier(.21,.07,.3,.97);
  }

  .SVG-4 {
    margin-left: 46.1539%;
    animation: svg4 1.2s cubic-bezier(.21,.07,.3,.97);
  }

  .SVG-5 {
    opacity: .5;
    margin-left: 69.2308%;
    margin-top: 13.3235%;
    animation: svg5 1.2s cubic-bezier(.21,.07,.3,.97);
  }

  .SVG-6 {
    margin-left: 69.2308%;
    margin-top: 39.9705%;
    animation: svg6 1.2s cubic-bezier(.21,.07,.3,.97);
  }

  @keyframes svg1 {
      0% {
          transform: translateX(-100vw) translateY(-30vw);
      }
      100% {
          transform: translateX(0) translateY(0);
      }
  }

  @keyframes svg2 {
      0% {
          transform: translateX(-100vw) translateY(-5vw);
      }
      100% {
          transform: translateX(0) translateY(0);
      }
  }

  @keyframes svg3 {
      0% {
          transform: translateX(-100vw) translateY(30vw);
      }
      100% {
          transform: translateX(0) translateY(0);
      }
  }

  @keyframes svg4 {
      0% {
          transform: translateX(100vw) translateY(-40vw);
      }
      100% {
          transform: translateX(0) translateY(0);
      }
  }

  @keyframes svg5 {
      0% {
          transform: translateX(100vw) translateY(5vw);
      }
      100% {
          transform: translateX(0) translateY(0);
      }
  }

  @keyframes svg6 {
      0% {
          transform: translateX(100vw) translateY(30vw);
      }
      100% {
          transform: translateX(0) translateY(0);
      }
  }

  .cardBackground {
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .projectCard {
    height: inherit;
    margin-bottom: 0;
  }

  .projectCardContent {
    width: inherit;
    height: inherit;
  }

  .projectCardHeader {
    margin: 1em;
  }

  .projectCardHeaderText {
    padding-left: 1em
  }

  .projectCardImg {
    width: inherit;
    max-width: none;
    height: 16.875vw;
  }

  .projectCardBody {
    margin: 1em
  }

  .projectLink :hover,
  .projectLink :focus {
    text-decoration: underline;
    color: #F34213
  }

  .projectCardContentEmpty {
    display: none
  }
}

/* Project View */

.projectViewBackground {
  width: 100vw;
  padding-top: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.projectContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center
}

.projectsHeader {
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
}

.projectsHeader::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 1px;
  background: #555;
  vertical-align: top;
  margin-top: 11px;
  margin-right: 10px;
}

.projectViewContain {
  padding: 2em 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.projectViewContainAlt {
  background-color: #2E2E3A ;
  color: #ffffff;;
}

.projectDetailContain {
  padding: 0 5vw;
}

.projectTitle h1 {
  padding-bottom: .5em;
}

.projectTitle h3 {
  font-style: italic;
}

.projectBody {
  padding: 2em 0;
}

.carouselContainer {
  width: 100vw;
  height: fit-content;
}

.Carousel__Container-sc-hyhecw-0 {
  z-index: 5;
}

.projectAsset {
  box-shadow: 1px 3px 3px 0px rgba(0,0,0,0.44);
  border-radius: 10px;
  width: 60vw;
  margin: 0 20vw
}

.swipeContain {
  position: absolute;
  z-index: 4;
  width: 100vw;
  margin-top: 60%;
  padding: 0 ;
  display: grid;
  grid-template-columns: 1fr 70vw 1fr;
  align-items: center;
  justify-content: center;
}

.leftArrow, .rightArrow {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.leftArrow {
  padding-left: 5vw;
  grid-column: 1;
  animation: arrowBounceX1 3s cubic-bezier(.09,.93,.47,1) infinite;
}

.rightArrow {
  padding-left: 5vw;
  grid-column: 3;
  animation: arrowBounceX2 3s cubic-bezier(.09,.93,.47,1) infinite;
}

.swipeArrow {
  width: 5vw;
}

@keyframes arrowBounceX1 {
  0% {
      transform: translateX(25vw);
      opacity: 0
  }
  50% {
      transform: translateX(0);
      opacity: 100%
  }
  90% {
      transform: translateX(25vw);
      opacity: 0
  }
  100% {
    transform: translateX(25vw);
    opacity: 0
  }
}

@keyframes arrowBounceX2 {
  0% {
    transform: translateX(-25vw);
    opacity: 0
}
  50% {
    transform: translateX(0);
    opacity: 100%
}
  90% {
    transform: translateX(-25vw);
    opacity: 0
  }
  100% {
    transform: translateX(-25vw);
    opacity: 0
  }
}

@media screen and (min-width: 767px){
  .projectViewContain{
    width: 90vw;
    padding: 5vw;
    display: grid;
    grid-template-columns: repeat(2, 42.5vw);
    grid-column-gap: 5vw;
    grid-template-rows: auto;
  }

  .projectViewContainAlt .projectDetailContain {
    grid-column: 2;
  }

  .projectViewContainAlt .carouselContainer {
    grid-column: 1;
    grid-row: 1;
  }

  .projectDetailContain {
    padding: 0
  }

  .carouselContainer {
    width: 42.5vw;
  }

  .projectAsset {
    width: 30vw;
    margin: 0 3.75vw;
  }

  .swipeContain {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (orientation: landscape){
  .projectViewContain{
    width: 75vw;
    padding: 5vw 12.5vw;
    grid-template-columns: repeat(2, 35vw);
  }

  .projectTitle {
    grid-column: 1
  }

  .projectImgMain,
  .projectBody {
    grid-column: 2
  }

  .projectBody {
    margin: 0;
  }

  .projectBody h3{
    margin-bottom: 2em;
  }

  .carouselContainer {
    width: 25vw;
    padding: 0 5vw
  }

  .projectAsset {
    width: 15vw;
    margin: 0 3.75vw;
  }
}

/* Footer */

.footerBackground {
  width: 100vw;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center
}

.footerSVG {
  max-height: 379px;
  margin-bottom: -4px;
}

.footerLinkBackground {
  width: inherit;
  background-color: #2E2E3A;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
}

.footerContain {
  align-self: center;
  width: 90vw;
}

.footerContain h2,
.footerContain h3 {
  color: #f1f2f3;
  margin-bottom: 1em
}

.footerLinkContain {
  color: #f1f2f388;
}

.footerLinkContain > *{
  margin-bottom: .6em
}

.footerContactLink {
  color: #51C5F8;
  margin-bottom: 1em
}

.footerContactLink:hover {
  color: #F34213
}

.footerLinkContain:last-child {
  margin-bottom: 0
}

.footerInnerContain {
  width: 70vw;
  margin: 2em 0;
  display: flex;
  justify-content: space-between;
}

.footerLink {
  color: #f1f2f388;
  font-size: .9rem;
  text-decoration: none;
}

.footerLink:hover,
.footerLink:focus{
  color: #f1f2f3bb;
  text-decoration: underline;
}

.cr {
  color: #f1f2f344;
  text-align: right;
}

@media screen and (min-width: 767px){

  .footerBackground {
    margin-top: 0
  }

  .footerSVG {
    display: none
  }

  .recatchContain,
  .footerInnerContain {
    width: 37.5vw;
  }
}

@media screen and (min-width: 767px){

  .footerBackground {
    margin-top: 0
  }

  .footerSVG {
    display: none
  }

  .recatchContain,
  .footerInnerContain {
    width: 37.5vw;
  }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
  .footerContain {
    width: 75vw;
  }
}

.resumeBackground {
  position: fixed;
  width: 100vw;
  height: 90vh;
}

.resumeFile {
  width: 100vw;
  height: 90vh;
}

/* Shared attributes */

@keyframes fadeStart {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeEnd {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeUpStart {
  0% {
    opacity: 0
  }
  50% {
    transform: translateY(5vh);
    opacity: 0
  }
  100% {
    transform: translateY(0);
    opacity: 1
  }
}

@keyframes fadeUpEnd {
  0% {
    transform: translateY(0);
    opacity: 1
  }
  50% {
    transform: translateY(5vh);
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@keyframes fadeRightStart {
  0% {
    opacity: 0
  }
  50% {
    transform: translateX(5vw);
    opacity: 0
  }
  100% {
    transform: translateX(0);
    opacity: 1
  }
}

@keyframes fadeRightEnd {
  0% {
    transform: translateX(0);
    opacity: 1

  }
  50% {
    transform: translateX(5vw);
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

.fade {
  -webkit-transition: color .3s cubic-bezier(.21,.07,.3,.97), background-color .3s cubic-bezier(.21,.07,.3,.97); /* For Safari 3.1 to 6.0 */
  transition: color .3s cubic-bezier(.21,.07,.3,.97), background-color .3s cubic-bezier(.21,.07,.3,.97), width .3s cubic-bezier(.21,.07,.3,.97);
}

.hidden {
  animation: fadeUpEnd 2s cubic-bezier(.21,.07,.3,.97);
  visibility: hidden;
}
